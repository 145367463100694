import { MobileNavigationComponent } from '@app/common/mobile-navigation/mobile-navigation.component'
import { BehaviorSubject, fromEvent, map, merge } from 'rxjs'
import { SubNavigationComponent } from 'src/app/common/header/components/sub-navigation/sub-navigation.component'

import { NavigationItem } from './shared/types'

import { AsyncPipe, NgFor, NgIf } from '@angular/common'
import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { Router, RouterLink, RouterLinkActive } from '@angular/router'

import { NavigationUrl } from 'src/app/core/constants/navigation'
import { AuthService } from 'src/app/core/services/auth.service'
import { LayoutService } from 'src/app/core/services/layout.service'
import { SsrService } from 'src/app/core/services/ssr.service'

import { SvgIcon } from 'src/app/shared/svg-icons/svg-icons.enum'

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    NgFor,
    MatMenuModule,
    MatIconModule,
    NgIf,
    MatIconModule,
    SubNavigationComponent,
    AsyncPipe,
    MobileNavigationComponent,
  ],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements AfterViewInit {
  @Input() items: NavigationItem[] = []
  @Input() isPortal!: boolean

  @Output() isSubNavigationEvent: EventEmitter<boolean> = new EventEmitter<boolean>()

  svgIcons = SvgIcon

  subNavigationItems$: BehaviorSubject<NavigationItem[]> = new BehaviorSubject<NavigationItem[]>([])
  isShowMobileNavigation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  navigation = NavigationUrl

  constructor(
    private router: Router,
    private authService: AuthService,
    private destroy: DestroyRef,
    private layoutService: LayoutService,
    private renderer2: Renderer2,
    private ssrService: SsrService,
  ) {
    this.subNavigationItems$.pipe(takeUntilDestroyed(this.destroy)).subscribe((value) => {
      this.isSubNavigationEvent.emit(!!value.length)
    })
  }

  ngAfterViewInit(): void {
    if (this.ssrService.isBrowser) {
      const keyboard$ = fromEvent<KeyboardEvent>(document, 'keydown').pipe(map(() => 'keyboard'))
      const mouse$ = fromEvent<MouseEvent>(document, 'mousedown').pipe(map(() => 'mouse'))

      merge(keyboard$, mouse$)
        .pipe(takeUntilDestroyed(this.destroy))
        .subscribe((inputType) => {
          if (inputType === 'keyboard') {
            document.body.classList.add('keyboard')
          } else if (inputType === 'mouse') {
            document.body.classList.remove('keyboard')
          }
        })
    }
  }

  menuEvent(key: string): void {
    const currentRole = this.authService.getCurrentMockRole()

    const isAdmin = currentRole === 'admin'
    switch (key) {
      case 'profile':
        this.router.navigate([isAdmin ? '/admin/profile' : '/profile'])
        break
      case 'logout':
        this.authService.setMockRole(null)
        this.router.navigate(['/login'])
        break
    }
  }

  openItems(item: NavigationItem): void {
    this.subNavigationItems$.next(item.items ?? [])
    this.layoutService.setShadow(true)
  }

  closeSubNavigation(): void {
    this.subNavigationItems$.next([])
    this.layoutService.setShadow(false)
  }

  closeMobile(): void {
    this.isShowMobileNavigation$.next(false)
  }

  openMobile(): void {
    this.isShowMobileNavigation$.next(true)
  }
}
