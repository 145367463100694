<div
  [class.header--portal]="isHero"
  [class.header--sub]="isSubNavigation$ | async"
  class="header flex items-center gap-20 w-full h-48 md:h-auto md:py-16 md:pl-8 md:pr-16"
  role="banner"
>
  <div *ngIf="!isBackHeader; else back" class="wrapper flex items-center gap-20 w-full h-full">
    <a
      class="header__support rounded-10 min-w-32 h-32 md:min-w-48 md:h-48 flex items-center justify-center bg-background-black"
      [routerLink]="navigation.Home"
      aria-label="Перейти на головну"
    >
      <mat-icon [svgIcon]="svgIcons.SUPPORT" alt="Іконка підтримки"></mat-icon>
    </a>

    <div class="header__navigations" role="navigation" aria-label="Основна навігація">
      <app-navigation [items]="mockNavigationItems" [isPortal]="isPortal" (isSubNavigationEvent)="subNavigationEvent($event)" />
    </div>

    <ng-container *ngIf="(isAuth$ | async) === false; else profile">
      <div class="header__menu header__menu--login">
        <app-btn
          [title]="'Увійти'"
          [hasGradient]="false"
          (clickEvent)="goToLogin()"
          class="header__login"
          aria-label="Увійти в особистий кабінет"
        ></app-btn>
      </div>
    </ng-container>

    <ng-template #profile>
      <div
        [matMenuTriggerFor]="menu"
        class="header__menu header__menu flex items-center gap-4 ml-auto"
        tabindex="0"
        role="menu"
        aria-label="Профіль"
        (keydown.enter)="$event.preventDefault(); pressMenu($event)"
      >
        <h6>{{ name }}</h6>
        <mat-icon [svgIcon]="svgIcons.POLYGON" alt="Іконка профілю"></mat-icon>
      </div>

      <mat-menu #menu="matMenu" class="profile-menu" xPosition="after" aria-label="Меню профілю">
        <a
          mat-menu-item
          (click)="menuEvent('profile')"
          (keydown.enter)="menuEvent('profile')"
          class="menu__item"
          aria-label="Перейти в профіль"
          >Mій профіль</a
        >
        <a
          mat-menu-item
          (click)="menuEvent('account')"
          (keydown.enter)="menuEvent('account')"
          class="menu__item"
          aria-label="Особистий кабінет"
          >Особистий кабінет</a
        >
        <button
          mat-menu-item
          (click)="menuEvent('logout')"
          (keydown.enter)="menuEvent('logout')"
          class="menu__item"
          aria-label="Вийти з облікового запису"
        >
          Вийти
        </button>
      </mat-menu>
    </ng-template>

    <div class="relative hidden xl:block">
      <button class="btn icon-transparent default" aria-label="Налаштування доступності" (click)="accessibilityService.toggle()">
        <mat-icon [svgIcon]="svgIcons.CONTRAST" alt="Іконка контрастності"></mat-icon>
      </button>

      <app-header-a11y
        *ngIf="accessibilityService.isOpen"
        @fade
        class="header__a11y"
        appClickOutside
        (appClickOutside)="hideAccess()"
        [shoulSkipFirst]="false"
      ></app-header-a11y>
    </div>
  </div>

  <ng-template #back>
    <div class="wrapper flex items-center gap-20 w-full h-full justify-between">
      <div
        class="flex gap-4 items-center cursor-pointer"
        (click)="goBack()"
        (keydown.enter)="goBack()"
        (keydown.space)="goBack()"
        (keydown.backspace)="goBack()"
        tabindex="0"
        aria-label="Повернутися на сайт"
      >
        <mat-icon [svgIcon]="svgIcons.ARROW_BACK" alt="Іконка назад"></mat-icon>
        <h6 class="hidden md:block">Повернутись на сайт</h6>
      </div>
      <div class="header__support">
        <mat-icon [svgIcon]="svgIcons.SUPPORT" alt="Іконка підтримки"></mat-icon>
      </div>
    </div>
  </ng-template>
</div>
